import React, { useEffect, useState } from "react";

import { AiOutlineSearch } from "react-icons/ai";

import { useStateContext } from "pages/context/StateContext";

import Table from "Utils/Table";

import { ToastContainer } from "react-toastify";

import TanTable from "Utils/TanTable";

import Modal from "../DeleteModals/Modal";
import EditModal from "../EditModal/Editmodal";
import DeleteSuccessMsg from "../SuccessMsg/DeleteSuccessMsg";
import MyAfrimall from "../../Assest/images/MyAfrimall.png";
import QuestIcon from "../../Assest/images/QuestIcon.png";

import CreateCustomerModal from "./createCustomer";
import SingleCustomer from "./singleCustomer";

const CustomerTable = () => {
  const { isLoading, allCustomers } = useStateContext();

  // useEffect(() => {
  //   getAllCustomers();
  // }, [page]);

  const headers = [
    "S/N",
    "Full Name",
    "Phone Number",
    "Email",
    "Status",
    "Actions",
  ];

  const [data, setData] = useState([]);
  const [selectedSerial, setSelectedSerial] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedData, setDisplayedData] = useState([]);

  const handleOpenModal = (item) => {
    setModalItem(item);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedSerial(null);
    setModalOpen(false);
  };

  const handleDelete = () => {
    const newData = data.filter((row) => row.serialNo !== selectedSerial);
    setData(newData);
    handleCloseModal();
    setShowSuccessMsg(true);
    setTimeout(() => {
      setShowSuccessMsg(false);
    }, 3000);
  };

  const handleEdit = (item) => {
    console.log("hey");
    setModalItem(item);
    // setModalOpen(true);
    setEditModalOpen(true);
  };

  const handleEditSubmit = (updatedData) => {
    const newData = data.map((row) =>
      row.serialNo === updatedData.serialNo ? updatedData : row
    );
    setData(newData);
  };

  const handleCloseSuccessMsg = () => {
    setShowSuccessMsg(false);
    // onClose();
  };

  useEffect(() => {
    const filteredData = allCustomers.filter((item) =>
      item.customer_fname?.includes(searchTerm)
    );
    setDisplayedData(filteredData);
  }, [searchTerm]);

  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Full Name",
      accessorKey: "customer_fname",
    },
    {
      header: "Phone Number",
      accessorKey: "customer_phone",
    },
    {
      header: "Email",
      accessorKey: "customer_email",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Actions",
      cell: (item) => (
        <div className="flex gap-2">
          <button
            onClick={() => handleEdit(item.row.original)}
            className="bg-green-600 text-white p-4 w-fit rounded-md px-6"
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  // console.log(allCustomers);

  return (
    <div className="">
      <ToastContainer />
      <div className="flex gap-2 text-black p-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M20.5 19.5V11C20.5 10.8448 20.4639 10.6916 20.3944 10.5528C20.325 10.4139 20.2242 10.2931 20.1 10.2L13.1 4.95C12.9269 4.82018 12.7164 4.75 12.5 4.75C12.2836 4.75 12.0731 4.82018 11.9 4.95L4.9 10.2C4.7758 10.2931 4.675 10.4139 4.60557 10.5528C4.53614 10.6916 4.5 10.8448 4.5 11V19.5C4.5 19.7652 4.60536 20.0196 4.79289 20.2071C4.98043 20.3946 5.23478 20.5 5.5 20.5H9.5C9.76522 20.5 10.0196 20.3946 10.2071 20.2071C10.3946 20.0196 10.5 19.7652 10.5 19.5V16.5C10.5 16.2348 10.6054 15.9804 10.7929 15.7929C10.9804 15.6054 11.2348 15.5 11.5 15.5H13.5C13.7652 15.5 14.0196 15.6054 14.2071 15.7929C14.3946 15.9804 14.5 16.2348 14.5 16.5V19.5C14.5 19.7652 14.6054 20.0196 14.7929 20.2071C14.9804 20.3946 15.2348 20.5 15.5 20.5H19.5C19.7652 20.5 20.0196 20.3946 20.2071 20.2071C20.3946 20.0196 20.5 19.7652 20.5 19.5Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h1>Home</h1>
        <svg
          className="pt-2"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M1.60047 0.800049L0.480469 2.00005L6.40047 8.00005L0.480469 14L1.60047 15.2L8.80047 8.00005L1.60047 0.800049Z"
            stroke="black"
            fill="#1F1E1E"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h1 className="text-[#049805]">Customers</h1>
      </div>
      <h1 className="font-bold px-4">Customer</h1>
      <div className="p-2 md:p-6 max-w-full overflow-x-auto">
        <div className="flex flex-col md:flex-row justify-between items-center p-3">
          {/* Button */}
          <div className="w-full md:w-auto mb-4 md:mb-0">
            <button
              onClick={() => setCreateModalOpen(true)}
              className="bg-[#049805] cursor-pointer text-white px-4 py-2 rounded w-full md:w-auto"
            >
              Add new customer
            </button>
          </div>

          {isCreateModalOpen && (
            <CreateCustomerModal onClose={() => setCreateModalOpen(false)} />
          )}

          {/* Search */}
          <div className="relative mt-4 md:mt-0 w-full md:w-1/2 lg:w-1/3">
            <input
              type="text"
              placeholder="Search..."
              className="bg-gray-200 text-gray-800 rounded-md py-2 pl-10 pr-4 w-full focus:outline-none focus:ring focus:border-blue-300"
              value={searchTerm || ""}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <AiOutlineSearch />
            </div>
          </div>
        </div>
        {/* csv */}
        <div className="m-4 flex text-xs gap-2">
          <a
            href="file" // Replace with the actual path to your CSV file
            download
            className="bg-[#E5E5E5]  text-black py-2 px-4 rounded"
          >
            CSV
          </a>

          <button
            // onClick={handleCopy}
            className="bg-[#E5E5E5]  text-black py-2 px-4 rounded"
          >
            Copy
          </button>
        </div>

        {/* MODAL confirm delete */}
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          title="Confirm Delete"
        >
          <img src={MyAfrimall} alt="logo" className="w-20 h-8 m-6" />
          <div className="flex justify-center">
            <img src={QuestIcon} alt="icon" className="w-[10re] h-[9rem]" />
          </div>
          {selectedSerial && (
            <p>
              Do you want to delete Customer's Details with name {"  "}
              {data.find((row) => row.serialNo === selectedSerial).fullName}
              and email {"  "}
              {data.find((row) => row.serialNo === selectedSerial).email}
              and Phone Number {"  "}
              {data.find((row) => row.serialNo === selectedSerial).phoneNumber}
              {/* and Address as{" "}
           {data.find((row) => row.serialNo === selectedSerial).address} */}
              ?
            </p>
          )}
          <div className="flex justify-between mt-4">
            <button
              className="bg-red-500 text-white px-4 py-1 rounded mr-2 w-full"
              onClick={handleDelete}
            >
              Delete
            </button>
            <button
              className="bg-gray-500 text-white px-4 py-1 rounded w-full"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
          </div>
        </Modal>
        {/* edit Modal */}
        {isEditModalOpen && (
          <EditModal
            onClose={() => setEditModalOpen(false)}
            onSubmit={handleEditSubmit}
            initialData={modalItem}
          />
        )}

        {showSuccessMsg && (
          <DeleteSuccessMsg
            isOpen={showSuccessMsg}
            onClose={handleCloseSuccessMsg}
            message="You have Successfully deleted Customer Details!"
          />
        )}

        {/* Table */}

        <TanTable
          columnData={columnData}
          data={allCustomers}
          loadingState={isLoading}
        />
      </div>
    </div>
  );
};

export default CustomerTable;
