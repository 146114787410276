import React, { useEffect, useState } from "react";

import { Link, NavLink } from "react-router-dom";

import { IoCloseOutline } from "react-icons/io5";

import { useStateContext } from "pages/context/StateContext";

import { IoIosPeople } from "react-icons/io";
import { FaCloudDownloadAlt } from "react-icons/fa";
import {
  MdAccountBalanceWallet,
  MdOutlineDeliveryDining,
} from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaRegLaugh } from "react-icons/fa";
import { CiSquareQuestion } from "react-icons/ci";
import { IoIosGitPullRequest } from "react-icons/io";
import { FaHandPointUp } from "react-icons/fa6";
import { PiAirplaneTiltLight } from "react-icons/pi";
import { LuShip } from "react-icons/lu";
import { TbPackageImport } from "react-icons/tb";
import { FaRegSadCry } from "react-icons/fa";

import logo from "../Assest/images/newLogo.png";
import Budget from "../../pages/Assest/images/budget.png";
import tag from "../../pages/Assest/images/tag.png";
import zoneIcon from "../../pages/Assest/images/zone.png";

import { getLinks } from "./sideBardata";

//Staff app
const someData = [
  {
    id: 3,
    text: "Leave/Off Requests",
    url: "leave",
    icon: <IoIosGitPullRequest />,
  },
  {
    id: 4,
    text: "Complaints/Suggestions",
    url: "complains",
    icon: <FaHandPointUp />,
  },
  {
    id: 23,
    text: "Query",
    url: "query",
    icon: <FaRegSadCry />,
  },
];

//affiliate
const AffiData = [
  {
    id: 5,
    text: "Total Affiliates",
    url: "affiliate/totalaffiliate",
    icon: <IoIosPeople />,
  },
  {
    id: 6,
    text: "Affiliates Bank Account",
    url: "affiliate/totalbankdetails",
    icon: <MdAccountBalanceWallet />,
  },
  {
    id: 7,
    text: "Pending Withdrawal",
    url: "affiliate/totalUnsuccessful",
    icon: <BiMoneyWithdraw />,
  },
  {
    id: 8,
    text: "Successful Withdrawals",
    url: "affiliate/successfulwithdrawals",
    icon: <FaRegLaugh />,
  },
  {
    id: 9,
    text: " Affiliate Requests",
    url: "affiliate/affiliaterequest",
    icon: <CiSquareQuestion />,
  },
];

//For Mobile Sub Menu
const MobileData = [
  {
    id: 10,
    text: "DHL Shipping",
    url: "mobile/expressshipping",
    icon: <MdOutlineDeliveryDining />,
  },
  {
    id: 11,
    text: "UPS Delivery",
    url: "mobile/budgetdelivery",
    icon: <img src={Budget} alt="budget" className="w-4 h-4" />,
  },
  {
    id: 12,
    text: "Air Cargo",
    url: "mobile/aircargo",
    icon: <PiAirplaneTiltLight />,
  },
  {
    id: 13,
    text: "Sea Cargo",
    url: "mobile/seacargo",
    icon: <LuShip />,
  },
  {
    id: 14,
    text: "Imports",
    url: "mobile/imports",
    icon: <TbPackageImport />,
  },
];
// Scanner
const ScannerData = [
  // {
  //   id: 15,
  //   text: "Scanned Items",
  //   url: "scanner/scanned-item",
  //   icon: <MdOutlineDeliveryDining />,
  // },
  // {
  //   id: 16,
  //   text: "History",
  //   url: "scanner/history",
  //   icon: <TbPackageImport />,
  // },
];

const subInventory = [
  {
    id: 17,
    text: "Vendors",
    url: "vendor",
    icon: <FaCloudDownloadAlt />,
  },
];

// Sub Menu for Prices
const PricesMenu = [
  {
    id: 18,
    text: "Zone",
    url: "zone",
    icon: <img src={zoneIcon} alt="zone" className="w-4 h-4" />,
  },
  {
    id: 19,
    text: "DHL/UPS Price",
    url: "dhlprice",
    icon: <img src={tag} alt="tag" className="w-4 h-4" />,
  },

  {
    id: 21,
    text: "Air Cargo Price",
    url: "Aircargoprice",
    icon: <img src={tag} alt="tag" className="w-4 h-4" />,
  },

  {
    id: 22,
    text: "Sea Cargo Price",
    url: "seacargoprice",
    icon: <img src={tag} alt="tag" className="w-4 h-4" />,
  },
];

const SideBar = ({ closeSidebar }) => {
  const [links, setLinks] = useState([]);
  const { logout } = useStateContext();
  useEffect(() => {
    const link = getLinks();
    setLinks(link);
  }, []);

  const [isStaffApp, setIsStaffApp] = useState(false);
  const [isAffiliate, setIsAffiliate] = useState(false);
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [isScannerApp, setIsScannerApp] = useState(false);
  const [isInventoryMenu, setIsInventoryMenu] = useState(false);
  // const [isPriceMenu, SetIsPriceMenu] = useState(false);

  function toggleStaffMenu() {
    setIsStaffApp((status) => !status);
    setIsAffiliate(false);
    setIsMobileMenu(false);
    setIsInventoryMenu(false);
    setIsScannerApp(false);
    // SetIsPriceMenu(false);
  }

  function toggleAffiliateMenu() {
    setIsAffiliate((status) => !status);
    setIsStaffApp(false);
    setIsMobileMenu(false);
    setIsInventoryMenu(false);
    setIsScannerApp(false);
    // SetIsPriceMenu(false);
  }

  function toggleMobileMenu() {
    setIsMobileMenu((status) => !status);
    setIsScannerApp(false);
    setIsAffiliate(false);
    setIsStaffApp(false);
    setIsInventoryMenu(false);
    // SetIsPriceMenu(false);
  }

  function toggleScannerMenu() {
    setIsScannerApp((status) => !status); // Toggle the state variable isScannerApp
    setIsAffiliate(false); // Reset other menu states
    setIsStaffApp(false);
    setIsInventoryMenu(false);
    setIsMobileMenu(false);
  }

  function toggleInventoryMenu() {
    setIsInventoryMenu((status) => !status);
    setIsAffiliate(false);
    setIsMobileMenu(false);
    setIsStaffApp(false);
    setIsScannerApp(false);
    // SetIsPriceMenu(false);
  }

  // function togglePriceMenu() {
  //   SetIsPriceMenu((status) => !status);
  //   setIsInventoryMenu(false);
  //   setIsAffiliate(false);
  //   setIsMobileMenu(false);
  //   setIsStaffApp(false);
  // }

  return (
    <div className="shadow-md h-screen p-4 overflowHide">
      <div className="flex justify-between">
        <img src={logo} className="md:w-40 w-24 h-[7rem]" alt="logo" />
        <IoCloseOutline
          className="text-3xl text-pry lg:hidden"
          onClick={closeSidebar}
        />
      </div>
      <div className="">
        <ul className="space-y-6 w-auto">
          {links.map((link) => (
            <li key={link.id} className="mb-2 px-2">
              <div>
                <NavLink
                  to={link.url}
                  onClick={
                    link.url === "affiliate"
                      ? toggleAffiliateMenu
                      : link.url === "mobile"
                      ? toggleMobileMenu
                      : link.url === "scanner"
                      ? toggleScannerMenu
                      : link.url === "inventory"
                      ? toggleInventoryMenu
                      : closeSidebar
                    // ? togglePriceMenu
                    // : link.url === "prices"
                  }
                  className={({ isActive }) =>
                    isActive
                      ? "space-x-2 py-2 bg-[#049805] text-white"
                      : "flex items-center space-x-2 text-black hover:bg-green-300 rounded-lg"
                  }
                >
                  <div className="bg-inherit flex items-center rounded-lg py-2 px-3">
                    {link.icon && <span className="w-6 h-6">{link.icon}</span>}
                    <p className="text-sm ml-2 flex space-x-15 items-center">
                      <span>{link.text}</span>
                      {link.url === "staff" && (
                        <span onClick={toggleStaffMenu}>
                          {link?.dropdownIcon}
                        </span>
                      )}
                      {link.url === "affiliate" && (
                        <span onClick={toggleAffiliateMenu}>
                          {link?.dropdownIcon}
                        </span>
                      )}
                      {link.url === "mobile" && (
                        <span onClick={toggleMobileMenu}>
                          {link?.dropdownIcon}
                        </span>
                      )}
                      {link.url === "scanner" && (
                        <span onClick={toggleScannerMenu}>
                          {link?.dropdownIcon}
                        </span>
                      )}
                      {link.url === "inventory" && (
                        <span onClick={toggleInventoryMenu}>
                          {link?.dropdownIcon}
                        </span>
                      )}
                      {/* {link.url === "prices" && (
                        <span onClick={togglePriceMenu}>
                          {link?.dropdownIcon}
                        </span>
                      )} */}
                    </p>
                  </div>
                </NavLink>
              </div>
              {isStaffApp && link.url === "staff" && (
                // Render the staff submenu directly under the staff button
                <ul className="pl-4 mt-2">
                  {someData.map((item) => (
                    <li key={item.id} className="flex items-center  space-y-3">
                      {item.icon && (
                        <span className="mr-2 pt-3">{item.icon}</span>
                      )}
                      <NavLink
                        to={item.url}
                        onClick={closeSidebar}
                        className={({ isActive }) =>
                          isActive
                            ? "text-[#049805] font-semibold"
                            : "text-black hover:text-[#049805]"
                        }
                      >
                        {item.text}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}

              {isAffiliate && link.url === "affiliate" && (
                // Render the affiliate submenu directly under the affiliate button
                <ul className="pl-4 mt-2">
                  {AffiData.map((item) => (
                    <li key={item.id} className="flex items-center  space-y-3">
                      {item.icon && (
                        <span className="mr-2 pt-3">{item.icon}</span>
                      )}
                      <NavLink
                        to={item.url}
                        onClick={closeSidebar}
                        className={({ isActive }) =>
                          isActive
                            ? "text-[#049805] font-semibold"
                            : "text-black hover:text-[#049805]"
                        }
                      >
                        {item.text}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
              {isMobileMenu && link.url === "mobile" && (
                // Render the affiliate submenu directly under the affiliate button
                <ul className="pl-4 mt-2">
                  {MobileData.map((item) => (
                    <li key={item.id} className="flex items-center  space-y-3">
                      {" "}
                      {/* Added the flex container */}
                      {item.icon && (
                        <span className="mr-2 pt-3">{item.icon}</span>
                      )}
                      <NavLink
                        to={item.url}
                        onClick={closeSidebar}
                        className={({ isActive }) =>
                          isActive
                            ? "text-[#049805] font-semibold"
                            : "text-black hover:text-[#049805]"
                        }
                      >
                        {item.text}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}

              {/* scanner */}
              {link.url === "scanner" && (
                <span onClick={toggleScannerMenu}>{link?.dropdownIcon}</span>
              )}
              {isScannerApp && link.url === "scanner" && (
                <ul className="pl-4 mt-2">
                  {ScannerData.map((item) => (
                    <li key={item.id} className="flex items-center  space-y-3">
                      {item.icon && (
                        <span className="mr-2 pt-3">{item.icon}</span>
                      )}
                      <NavLink
                        to={item.url}
                        onClick={closeSidebar}
                        className={({ isActive }) =>
                          isActive
                            ? "text-[#049805] font-semibold"
                            : "text-black hover:text-[#049805]"
                        }
                      >
                        {item.text}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}

              {/* {isInventoryMenu && link.url === "inventory" && (
                // Render the inventory submenu directly under the inventory button
                <ul className="pl-4 mt-2">
                  {subInventory.map((item) => (
                    <li key={item.id} className="flex items-center  space-y-3">
                      {item.icon && (
                        <span className="mr-2 pt-3">{item.icon}</span>
                      )}
                      <NavLink
                        to={item.url}
                        onClick={closeSidebar}
                        className={({ isActive }) =>
                          isActive
                            ? "text-[#049805] font-semibold"
                            : "text-black hover:text-[#049805]"
                        }
                      >
                        {item.text}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )} */}
              {/* {isPriceMenu && link.url === "prices" && (
                // Render the prices submenu directly under the inventory button
                <ul className="pl-4 mt-2">
                  {PricesMenu.map((item) => (
                    <li key={item.id} className="flex items-center  space-y-3">
                      {item.icon && (
                        <span className="mr-2 pt-3">{item.icon}</span>
                      )}
                      <NavLink
                        to={item.url}
                        onClick={closeSidebar}
                        className={({ isActive }) =>
                          isActive
                            ? "text-[#049805] font-semibold"
                            : "text-black hover:text-[#049805]"
                        }
                      >
                        {item.text}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )} */}
            </li>
          ))}

          {/* Other menu items */}
        </ul>
        <Link
          onClick={logout}
          to="/"
          className="bg-gray-100 p-2 rounded-md w-full block text-center mt-10"
        >
          Logout
        </Link>
      </div>
    </div>
  );
};

export default SideBar;
