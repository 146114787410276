import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "pages/context/StateContext";
import TanTable from "Utils/TanTable";
import { Spinner } from "react-activity";

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const ComplaintsTable = () => {
  const { allComplaints, getAllComplaints, getViewComplaints } =
    useStateContext();

  const [complains, setComplains] = useState();
  const [loadingId, setLoadingId] = useState(null);

  useEffect(() => {
    const result = async () => {
      await getAllComplaints();
    };
    result();
  }, []);

  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Name",
      accessorKey: "name",
      cell: (item) => (
        <span>
          {item.row.original.user.firstname} {item.row.original.user.lastname}
        </span>
      ),
    },
    {
      header: "Type",
      accessorKey: "comment_type",
    },
    {
      header: "Date",
      accessorKey: "created_at",
      cell: ({ row }) => {
        const date = row.original.created_at;
        return <div className="capitalize">{formatDate(date)}</div>;
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (props) => (
        <div className="flex items-center justify-start">
          <span className="capitalize">{props.row.original.status}</span>
          {/* Add view button with navigation */}
          <button
            onClick={() => handleViewButton(props.row.original.id)}
            className="ml-2 bg-[#3771C8] text-white border p-2 px-4 rounded"
            disabled={loadingId === props.row.original.id}
          >
            {loadingId === props.row.original.id ? <Spinner /> : "View"}
          </button>
        </div>
      ),
    },
  ];

  const navigate = useNavigate();

  const handleViewButton = async (complaintId) => {
    setLoadingId(complaintId);
    try {
      await getViewComplaints();
      navigate(`/home/complains/${complaintId}`);
    } catch (error) {
      console.error("Error loading complaint details:", error);
    } finally {
      setLoadingId(null);
    }
  };

  return (
    <div>
      <div>
        <TanTable
          columnData={columnData}
          data={allComplaints}
          loadingState={loadingId !== null}
        />
      </div>
    </div>
  );
};

export default ComplaintsTable;
