import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";

import axios from "axios";

import { toast } from "react-toastify";

import pLimit from "p-limit";

import debounce from "lodash/debounce";

const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const token = localStorage.getItem("token");

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedSerial, setSelectedSerial] = useState(null);
  const [numToShow, setNumToShow] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [batches, setBatches] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allAirCargos, setAllAirCargos] = useState([]);
  const [allSeaCargos, setAllSeaCargos] = useState([]);
  const [allAdmin, setAllAdmin] = useState([]);
  const [dashDetails, setDashdetails] = useState({});
  const [batchDetails, setBatchesDetails] = useState([]);
  const [pendingPayment, setPendingPayment] = useState([]);
  const [singleBatchDetails, setSingleBacthDetail] = useState(null);
  const [airItems, setAirItems] = useState({});
  const [seaaItems, setSeaItems] = useState({});
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [affiliateDetails, setAffiliateDetails] = useState(null);
  const [allNews, setAllNews] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [shipmentDetails, setShipmentDetails] = useState(null);
  const [allAffiliates, setAllAffiliates] = useState([]);
  const [allStaff, setAllStaff] = useState([]);
  const [StaffProfile, setStaffProfile] = useState([]);
  const [allLeave, setAllLeave] = useState([]);
  const [allExpress, setAllExpress] = useState([]);
  const [allUps, setAllUps] = useState([]);
  const [sea, setAllSea] = useState([]);
  const [air, setAllAir] = useState([]);
  const [singleExpress, setSingleExpress] = useState({});
  const [singleUps, setSingleUps] = useState([]);
  const [singleSea, setSingleSea] = useState([]);
  const [singleAir, setSingleAir] = useState([]);
  const [singleImport, setSingleImport] = useState([]);
  const [allComplaints, setAllComplaints] = useState([]);
  const [ViewComplaints, setViewComplaints] = useState([]);
  const [PendingLeaveRequest, setPendingLeaveRequest] = useState([]);
  const [RestoreStaff, setRestoreStaff] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [deleteInventory, setDeleteInventory] = useState([]);
  const [staffPoint, setStaffPoint] = useState([]);
  const [image, setImage] = useState(null);
  const [viewAllTotalAffi, SetViewAllTotalAffi] = useState([]);
  const [allCountry, setAllCountry] = useState([]);
  const [viewAllAffiReq, SetViewAllAffiReq] = useState([]);
  const [viewAllAffiBankAcct, setViewAllAffiBankAcct] = useState([]);
  const [zones, setZones] = useState([]);
  const [shipmentPrices, setShipmentPrices] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [allQuery, setAllQuery] = useState([]);
  const [allDhlPrice, setAllDhlPrice] = useState([]);
  const [allUpsPrice, setAllUpsPrice] = useState([]);
  const [allCargoPrice, setAllCargoPrice] = useState([]);
  const [allimports, setAllImports] = useState([]);
  const [viewAllAffisuccesswithdraw, setViewAllAffisuccesswithdraw] = useState(
    []
  );
  const [viewAllPendingAffiWithdraw, setViewAllPendingAffiWithdraw] = useState(
    []
  );

  // const [token, setToken] = useState(null);

  const [isStaffDeactivated, setIsStaffDeactivated] = useState(false);

  const handleShipmentDetails = (e) => {
    const { value, name } = e.target;
    setShipmentDetails({ ...shipmentDetails, [name]: value });
  };

  const config = () => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };

  const uploadConfig = () => {
    // const token = await getToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  };

  // const baseUrl = "https://sandbox.myafrimall.com.ng/api/v1";
  // const imageUrl = "https://sandbox.myafrimall.com.ng";

  const baseUrl = "https://api.myafrimall.com.ng/api/v1";
  const imageUrl = "https://api.myafrimall.com.ng";

  const [formData, setFormData] = useState({});

  const [editData, setEditData] = useState({
    customerName: "",
    trackingNumber: "",
    shipmentType: "",
  });

  // console.log(token);

  const initializeEditData = (initialData = {}) => {
    setEditData({
      customerName: initialData.CustomerName || "",
      trackingNumber: initialData.TrackingNumber || "",
      shipmentType: initialData.ShipmentType || "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value); // <-- Add this line
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (editData, navigate, location) => {
    location.state?.updateData(data);
    navigate("/aircargo");
    // console.log("Updated data:", editData);
  };

  const getAllBatches = async () => {
    const url = `${baseUrl}/admin/get-all-batches`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      setBatches(res.data.data);
    } catch (error) {
      //  console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const getShipmentPrices = async () => {
  //   const url = `${baseUrl}/admin/shipment-price`;
  //   setIsLoading(true);
  //   try {
  //     const res = await axios.get(url, config(token));
  //     setShipmentPrices(res.data.data);
  //   } catch (error) {
  //     //  console.log(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const getBatchDetails = async () => {
    const id = localStorage.getItem("batch_id");
    const url = `${baseUrl}/admin/get-single-batch/${id}?page=${page}`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      setBatchesDetails(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllCustomers = async () => {
    const url = `${baseUrl}/admin/get-all-customer`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      setAllCustomers(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // vendors
  const getAllVendors = async () => {
    const url = `${baseUrl}/admin/vendors`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      setAllVendors(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllQuery = async () => {
    const url = `${baseUrl}/admin/query`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      setAllQuery(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllDhlPrice = async () => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/dhl-shipment-price`;
    try {
      const res = await axios.get(url, config(token));
      setAllDhlPrice(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllUpsPrice = async () => {
    const url = `${baseUrl}/admin/ups-shipment-price`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      setAllUpsPrice(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  //allcargo price
  const getAllCargoPrice = async () => {
    const url = `${baseUrl}/admin/cargo-price`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      setAllCargoPrice(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  // all imports
  const getAllImports = async () => {
    const url = `${baseUrl}/admin/imports`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      setAllImports(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllLocations = async () => {
    const url = `${baseUrl}/admin/get-all-location`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      setAllLocations(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllAirCargos = async () => {
    const url = `${baseUrl}/admin/aircargo`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      setAllAirCargos(res.data.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllSeaCargos = async () => {
    const url = `${baseUrl}/admin/seacargo`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      setAllSeaCargos(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllAdmins = async () => {
    const url = `${baseUrl}/admin/all-admin?page=${page}`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      // console.log(res.data);
      setAllAdmin(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getAllPendingPayments = async () => {
    const url = `${baseUrl}/admin/pending-payments?page=${page}`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      setPendingPayment(res.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllCountries = async () => {
    const url = `${baseUrl}/admin/get-all-country`;
    try {
      const res = await axios.get(url, config(token));
      setCountries(res.data.data);
    } catch (error) {
      // console.log(error);
    }
  };
  const getAllZones = async () => {
    const url = `${baseUrl}/admin/zone`;
    try {
      const res = await axios.get(url, config(token));
      setZones(res.data.data);
      // console.log("zones:", res.data.data)
    } catch (error) {
      // console.log(error);
    }
  };

  // get all country
  const getAllCountry = async () => {
    // setIsLoading(true);
    const url = `${baseUrl}/admin/shipment-country`;
    try {
      const res = await axios.get(url, config(token));
      // toast.success(res?.data?.message);
      setAllCountry(res.data.data);
    } catch (error) {}
  };

  const getDashboardDetails = async () => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/admin-dashboard`;
    try {
      const res = await axios.get(url, config(token));
      setDashdetails(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getAirItems = async (id) => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/get-single-aircargo/${id}`;
    try {
      const res = await axios.get(url, config(token));
      setAirItems(res.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // single express/dhl
  const getSingleExpress = async (id) => {
    const url = `${baseUrl}/admin/express/${id}`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      //console.log(res)
      if (res.data && res.data.status) {
        setSingleExpress(res.data.data);
      } else {
        // Handle no data found case
        setSingleExpress(null);
      }
    } catch (error) {
      // Handle error case
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  //single budget/ups
  const getSingleUps = async (id) => {
    const url = `${baseUrl}/admin/budget/${id}`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      console.log(res);
      if (res.data && res.data.status) {
        setSingleUps(res.data.data);
      } else {
        // Handle no data found case
        setSingleUps(null);
      }
    } catch (error) {
      // Handle error case
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  //single sea mobile/
  const getSingleSea = async (id) => {
    const url = `${baseUrl}/admin/sea/${id}`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      console.log(res);
      if (res.data && res.data.status) {
        setSingleSea(res.data.data);
      } else {
        // Handle no data found case
        setSingleSea(null);
      }
    } catch (error) {
      // Handle error case
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  //single Air mobile/
  const getSingleAir = async (id) => {
    const url = `${baseUrl}/admin/air/${id}`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      console.log(res);
      if (res.data && res.data.status) {
        setSingleAir(res.data.data);
      } else {
        // Handle no data found case
        setSingleAir(null);
      }
    } catch (error) {
      // Handle error case
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  //single import
  const getSingleImport = async (id) => {
    const url = `${baseUrl}/admin/imports/${id}`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      console.log(res);
      if (res.data && res.data.status) {
        setSingleImport(res.data.data);
      } else {
        // Handle no data found case
        setSingleImport(null);
      }
    } catch (error) {
      // Handle error case
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getAffilateDetails = async (id) => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/affiliate-dashboard`;
    try {
      const res = await axios.get(url, config(token));
      setAffiliateDetails(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllAffiliates = async (id) => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/get-affiliate`;
    try {
      const res = await axios.get(url, config(token));
      setAllAffiliates(res.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // view all affiliate
  const GetAllAffiliatesViewAll = async () => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/affiliates`;
    try {
      const response = await axios.get(url, config(token));
      SetViewAllTotalAffi(response.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  //view all affiliate req
  const GetAllAffiReqViewAll = async () => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/affiliate-requests`;
    try {
      const response = await axios.get(url, config(token));
      // console.log(response.data.data);
      SetViewAllAffiReq(response.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  //view all affiliate bank acct
  const GetViewAllAffiBankAcct = async () => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/all-bank-accounts`;
    try {
      const response = await axios.get(url, config(token));
      // console.log(response)
      setViewAllAffiBankAcct(response.data.data);
    } catch (error) {
      //  console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  //view all affiliate success withdrawal
  const GetViewAllAffisuccesswithdraw = async () => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/all-successful-withdrawals`;
    try {
      const response = await axios.get(url, config(token));
      // console.log(response)
      setViewAllAffisuccesswithdraw(response.data.data);
    } catch (error) {
      //  console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // view all Unsuccessful / pending Withdrawal
  const GetViewAllPendingAffiWithdraw = async () => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/all-pending-withdrawals`;
    try {
      const response = await axios.get(url, config(token));
      setViewAllPendingAffiWithdraw(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSeaItems = async (id) => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/get-single-seacargo/${id}`;
    try {
      const res = await axios.get(url, config(token));
      setSeaItems(res.data);
    } catch (error) {
      //  console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getNews = async () => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/all-news`;
    try {
      const res = await axios.get(url, config(token));
      setAllNews(res.data.data);
    } catch (error) {
      //  console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteNews = async (id) => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/delete-news/${id}`;
    try {
      const res = await axios.delete(url, config(token));
      toast.success("Deleted successfully");
      getNews();
    } catch (error) {
      toast.error("An error occurred");
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatePayment = async ({ id, status, type }) => {
    setPaymentLoading(true);
    const url1 = `${baseUrl}/admin/update-single-aircargo-payment/${id}`;
    const url2 = `${baseUrl}/admin/update-single-seacargo-payment/${id}`;
    let url;
    if (type === "sea") {
      url = url2;
    } else if (type === "air") {
      url = url1;
    }
    try {
      const res = await axios.post(
        url,
        { payment_status: status },
        config(token)
      );
      toast.success("Payment status updated successfully");
      getAllAirCargos();
      getAllSeaCargos();
    } catch (error) {
      //  console.log(error);
    } finally {
      setPaymentLoading(false);
    }
  };

  const updateTracking = async ({ id, status, type, desc }) => {
    setPaymentLoading(true);
    let payload = {
      shipment_status: status,
      tracking_description: desc,
    };

    const url1 = `${baseUrl}/admin/update-single-aircargo-tracking/${id}`;
    const url2 = `${baseUrl}/admin/update-single-seacargo-tracking/${id}`;
    const url3 = `${baseUrl}/admin/update-batch-tracking`;
    let url;

    if (type === "sea") {
      url = url2;
    } else if (type === "air") {
      url = url1;
    } else if (type === "batch") {
      url = url3;
      payload = {
        batch_id: id,
        status,
        description: desc,
      };
    }

    try {
      const res = await axios.post(url, payload, config(token));
      toast.success("Tracking details updated successfully");
      getAllAirCargos();
      getAllSeaCargos();
    } catch (error) {
      //  console.log(error);
    } finally {
      setPaymentLoading(false);
    }
  };

  const confirmShipment = async () => {
    try {
      setIsLoading(true);

      const url = `${baseUrl}/admin/create-shipments`;

      const formData = new FormData();
      formData.append("package_picture", image);
      const { sub_price, ...cleanedShipmentDetails } = shipmentDetails;

      const shipment = await axios.post(
        url,
        { ...cleanedShipmentDetails, acceptance: "0" },
        config(token)
      );
      const id = shipment.data.data.shipment_id;
      const url2 = `${baseUrl}/admin/add-package-picture/${id}`;
      const res = await axios.post(url2, formData, config(token));
      getAllAirCargos();
      getAllSeaCargos();
      toast.success(shipment?.data?.message);
      return "success";
    } catch (error) {
      // console.error(error);
      return "error";
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    const url = `${baseUrl}/admin/logout`;
    try {
      const res = await axios.post(url, {}, config(token));
    } catch (error) {
    } finally {
      setIsLoggedIn(false);
      localStorage.clear();
    }
  };

  const getAllStaff = async () => {
    const url = `${baseUrl}/admin/all-staff`;
    try {
      const res = await axios.get(url, config(token));
      setAllStaff(res.data.data);
      // console.log(res.data.data);
    } catch (error) {}
  };

  const getStaffProfile = async (id) => {
    const url = `${baseUrl}/admin/staff/${id}`;
    try {
      const res = await axios.get(url, config(token));
      setStaffProfile(res.data.data);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getAllLeave = async () => {
    const url = `${baseUrl}/admin/leave-request`;
    try {
      const res = await axios.get(url, config(token));
      setAllLeave(res.data.data);
    } catch (error) {}
  };

  //get all mobile DHL/express shipping
  const getAllExpress = async () => {
    const url = `${baseUrl}/admin/express`;
    try {
      const res = await axios.get(url, config(token));
      //  console.log(res)
      setAllExpress(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  //get all mobile ups/budget shipping
  const getAllUps = async () => {
    const url = `${baseUrl}/admin/budget`;
    try {
      const res = await axios.get(url, config(token));
      //console.log(res)
      setAllUps(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  //get all mobile sea shipping
  const getAllSea = async () => {
    const url = `${baseUrl}/admin/sea`;
    try {
      const res = await axios.get(url, config(token));
      //  console.log(res)
      setAllSea(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  //get all mobile air shipping
  const getAllAir = async () => {
    const url = `${baseUrl}/admin/air`;
    try {
      const res = await axios.get(url, config(token));
      //  console.log(res)
      setAllAir(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPendingLeaveRequest = async (id) => {
    const url = `${baseUrl}/admin/leave-request/${id}`;
    try {
      const res = await axios.get(url, config(token));
      setPendingLeaveRequest(res.data);
      // setPendingLeaveRequest(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllComplaints = async () => {
    const url = `${baseUrl}/admin/suggestion`;
    try {
      const res = await axios.get(url, config(token));
      setAllComplaints(res.data.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const getViewComplaints = async (id) => {
    const url = `${baseUrl}/admin/suggestion/${id}`;
    try {
      const res = await axios.get(url, config(token));
      setViewComplaints(res.data.data);
    } catch (error) {}
  };
  const getDeleteStaff = async (id) => {
    const url = `${baseUrl}/admin/delete-staff/${id}`;
    try {
      const res = await axios.delete(url, config(token));
      getAllStaff();
      toast.success(res?.data?.message);
    } catch (error) {
      console.log(error);
    }
  };

  const getRestoreStaff = async (id) => {
    const url = `${baseUrl}/admin/restore-staff/${id}`;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const res = await axios.post(url, null, { headers });
      getAllStaff();
      setRestoreStaff(res.data);
      toast.success(res?.data?.message);
      // setIsStaffDeactivated(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getInventory = async () => {
    const url = `${baseUrl}/admin/inventory`;
    try {
      const res = await axios.get(url, config(token));
      setInventory(res.data.data);
    } catch (error) {}
  };

  const getDeleteInventory = async (id) => {
    const url = `${baseUrl}/admin/delete-inventory/${id}`;
    try {
      const res = await axios.delete(url, config(token));
      toast.success(res?.data?.message);
      getInventory();
    } catch (error) {
      console.log(error);
    }
  };

  const getStaffPoint = async (id) => {
    const url = `${baseUrl}/admin/staff-point/${id}`;
    setIsLoading(true);
    try {
      const res = await axios.get(url, config(token));
      setStaffPoint(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const getScanHistory = async () => {
  // 	setIsLoading(true);
  // 	const { data } = await getRequest("/history");
  // 	setHistory(data.data);
  // };

  // const loadData = () => {
  //   getAllCountries();
  //   getAllLocations();
  //   getDashboardDetails();
  //   getAffilateDetails();
  //   getNews();
  //   getAllAffiliates();
  //   getAllStaff();
  //   getAllLeave();
  //   getAllComplaints();
  //   getViewComplaints();
  //   GetViewAllAffisuccesswithdraw();
  //   getAllAirCargos();
  //   getAllSeaCargos();
  //   GetAllAffiliatesViewAll();
  //   GetAllAffiReqViewAll();
  //   GetViewAllAffiBankAcct();
  //   GetViewAllPendingAffiWithdraw();
  //   getAllExpress();
  //   getAllUps();
  //   getAllSea();
  //   getAllAir();
  //   getInventory();
  //   getAllZones();
  //   getAllCountry();
  //   getAllQuery();
  //   getAllDhlPrice();
  //   getAllUpsPrice();
  //   // getShipmentPrices();
  // };

  // const loadDataPerPage = () => {
  //   getAllBatches();
  //   getAllCustomers();
  //   getAllAdmins();
  //   getAllPendingPayments();
  //   getAllVendors();
  //   getAllCargoPrice();
  //   getAllImports()
  //   // getAllLeave();
  //   getPendingLeaveRequest()
  //   getAllComplaints();
  // };

  // useEffect(() => {
  //   if (token !== null) {
  //     loadData();
  //   }
  // }, [token, isLoggedIn]);

  // useEffect(() => {
  //   if (token !== null) {
  //     loadDataPerPage();
  //   }
  // }, [token, isLoggedIn, page]);

  const limit = pLimit(5);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const promises = [
        limit(() => getAllCountries()),
        limit(() => getAllLocations()),
        limit(() => getDashboardDetails()),
        limit(() => getAffilateDetails()),
        limit(() => getNews()),
        limit(() => getAllAffiliates()),
        limit(() => getAllStaff()),
        limit(() => getAllLeave()),
        limit(() => getAllComplaints()),
        limit(() => getViewComplaints()),
        limit(() => GetViewAllAffisuccesswithdraw()),
        allAirCargos.length === 0
          ? limit(() => getAllAirCargos())
          : Promise.resolve(),
        limit(() => getAllSeaCargos()),
        limit(() => GetAllAffiliatesViewAll()),
        limit(() => GetAllAffiReqViewAll()),
        limit(() => GetViewAllAffiBankAcct()),
        limit(() => GetViewAllPendingAffiWithdraw()),
        limit(() => getAllExpress()),

        allExpress.length === 0
          ? limit(() => getAllExpress())
          : Promise.resolve(),

        limit(() => getAllUps()),
        limit(() => getAllSea()),
        limit(() => getAllAir()),
        limit(() => getInventory()),
        limit(() => getAllZones()),
        limit(() => getAllCountry()),
        limit(() => getAllQuery()),

        allDhlPrice.length === 0
          ? limit(() => getAllDhlPrice())
          : Promise.resolve(),

          allUpsPrice.length === 0
          ? limit(() => getAllUpsPrice())
          : Promise.resolve(),
      ];

      await Promise.allSettled(promises);
      console.log("All data loaded successfully");
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []); // Updated dependency

  const loadDataPerPage = useCallback(
    debounce(async () => {
      setIsLoading(true);
      try {
        const promises = [
          limit(() => getAllBatches()),
          limit(() => getAllCustomers()),
          limit(() => getAllAdmins()),
          limit(() => getAllPendingPayments()),
          // limit(() => getAllVendors()),

          allVendors.length === 0
          ? limit(() => getAllVendors())
          : Promise.resolve(),
          limit(() => getAllCargoPrice()),
          limit(() => getAllImports()),
          limit(() => getAllLeave()),
          limit(() => getPendingLeaveRequest()),
          limit(() => getAllComplaints()),
        ];

        await Promise.allSettled(promises);
        console.log("Per-page data loaded successfully");
      } catch (error) {
        console.error("Error loading per-page data:", error);
      } finally {
        setIsLoading(false);
      }
    }, 300), // 300ms debounce
    []
  );

  useEffect(() => {
    if (token) {
      loadData();
    }
  }, [token, isLoggedIn, loadData]);

  useEffect(() => {
    if (token) {
      loadDataPerPage();
    }
  }, [token, isLoggedIn, page, loadDataPerPage]);

  return (
    <StateContext.Provider
      value={{
        isModalOpen,
        setModalOpen,
        selectedSerial,
        setSelectedSerial,
        searchTerm,
        setSearchTerm,
        data,
        setData,
        numToShow,
        setNumToShow,
        formData,
        setFormData,
        batches,
        setBatches,
        editData,
        setEditData,
        handleInputChange,
        handleSubmit,
        initializeEditData,
        baseUrl,
        imageUrl,
        getAllBatches,
        // getShipmentPrices,
        shipmentPrices,
        getAllCountries,
        page,
        setPage,
        countries,
        token,
        config,
        uploadConfig,
        isLoading,
        setIsLoading,
        getAllCustomers,
        getAllVendors,
        getAllCargoPrice,
        allCargoPrice,
        setAllCargoPrice,
        allCustomers,
        allVendors,
        getAllLocations,
        allLocations,
        getAllAirCargos,
        allAirCargos,
        getAllSeaCargos,
        allSeaCargos,
        getAllAdmins,
        allAdmin,
        getDashboardDetails,
        dashDetails,
        batchDetails,
        getBatchDetails,
        pendingPayment,
        getAllPendingPayments,
        setSingleBacthDetail,
        singleBatchDetails,
        getAirItems,
        airItems,
        getSeaItems,
        seaaItems,
        updatePayment,
        paymentLoading,
        updateTracking,
        getAffilateDetails,
        affiliateDetails,
        getNews,
        allNews,
        deleteNews,
        logout,
        token,
        setIsLoggedIn,
        handleShipmentDetails,
        shipmentDetails,
        setShipmentDetails,
        getAllAffiliates,
        allAffiliates,
        confirmShipment,
        allStaff,
        getAllStaff,
        StaffProfile,
        getStaffProfile,
        isStaffDeactivated,
        setIsStaffDeactivated,
        allLeave,
        setAllLeave,
        setPendingLeaveRequest,
        getAllLeave,
        PendingLeaveRequest,
        getPendingLeaveRequest,
        allComplaints,
        getAllComplaints,
        getViewComplaints,
        // DeleteStaff,
        getDeleteStaff,
        RestoreStaff,
        getRestoreStaff,
        setImage,
        GetAllAffiliatesViewAll,
        viewAllTotalAffi,
        viewAllAffiReq,
        GetAllAffiReqViewAll,
        viewAllAffiBankAcct,
        GetViewAllAffiBankAcct,
        viewAllAffisuccesswithdraw,
        GetViewAllAffisuccesswithdraw,
        viewAllPendingAffiWithdraw,
        GetViewAllPendingAffiWithdraw,
        allExpress,
        setAllExpress,
        getAllExpress,
        setSingleExpress,
        singleExpress,
        getSingleExpress,
        getAllUps,
        allUps,
        setAllUps,
        singleUps,
        setSingleUps,
        getSingleUps,
        getAllSea,
        sea,
        setAllAir,
        air,
        singleSea,
        setSingleSea,
        getSingleSea,
        singleAir,
        setSingleAir,
        getSingleAir,

        getInventory,
        inventory,
        getDeleteInventory,
        staffPoint,
        getStaffPoint,
        allCountry,
        //setSingleAffiliate,
        // getSingleAffiliate,
        // singleAffiliate,
        getAllCountry,
        isLoggedIn,
        zones,
        getAllZones,
        setAllVendors,
        setAllQuery,
        setAllDhlPrice,
        setAllUpsPrice,
        allUpsPrice,
        allQuery,
        allDhlPrice,
        allimports,
        setAllImports,
        singleImport,
        setSingleImport,
        getSingleImport,
        getAllDhlPrice,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => {
  return useContext(StateContext);
};
