import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import TanTable from "Utils/TanTable";

import Modal from "../DeleteModals/Modal";
import { useStateContext } from "../../context/StateContext";
import MyAfrimall from "../../Assest/images/newLogo.png";

import UpdateAllTrackingModal from "./UpdateAllTrackingModal";

const CreateBatchesTable = () => {
  const { batches } = useStateContext();

  const { isModalOpen, setModalOpen, setSelectedSerial, isLoading } =
    useStateContext();
  const navigate = useNavigate();

  const [itemToDelete, setItemToDelete] = useState(null);
  const [isUpdateAllTrackingModal, setUpdateAllTrackingModal] = useState(false);
  const [data, setData] = useState(null);

  const {
    batch_enddate,
    batch_number,
    batch_startdate,
    country,
    id,
    shipment_type,
    setSingleBatch,
  } = itemToDelete || {};

  const handleOpenModal = (item) => {
    // console.log(item);
    setItemToDelete(item);
    setModalOpen(true);
    // setSingleBatch(item);
  };

  const handleCloseModal = () => {
    setSelectedSerial(null);
    setModalOpen(false);
  };

  const handleDelete = () => {};

  const handleOpenUpdateAllTrackingModal = (item) => {
    // console.log(item);
    setData(item);
    setUpdateAllTrackingModal(true);
  };

  const handleCloseUpdateAllTrackingModal = () => {
    setUpdateAllTrackingModal(false);
  };

  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Batch No",
      accessorKey: "batch_number",
    },
    {
      header: "Start Date",
      accessorKey: "batch_startdate",
    },
    {
      header: "End Date",
      accessorKey: "batch_enddate",
    },
    {
      header: "Shipment Type",
      accessorKey: "shipment_type",
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: (item) => {
        const currentItem = item.row.original;
        return (
          <div className="flex gap-2">
            <button
              onClick={() => handleOpenModal(currentItem)}
              className="bg-red-600 text-white p-2 w-20 rounded-md"
            >
              Delete
            </button>
            <button
              onClick={async () => {
                await localStorage.setItem("batch_id", currentItem?.id);
                navigate("/home/details");
              }}
              className="bg-green-600 text-white p-2 w-20 rounded-md"
            >
              View
            </button>
            <button
              onClick={() => handleOpenUpdateAllTrackingModal(currentItem)}
              className="bg-blue-600 text-white text-xs p-2 w-32 rounded-md"
            >
              Update Tracking
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="">
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Confirm Delete"
        className = ""
      >
        <img src={MyAfrimall} alt="logo" className="w-[7rem] h-[6rem]" />

        <p className="">
          Do you want to delete the batch which has the Start Date{" "}
          {batch_startdate} as AND End Date as {batch_enddate} and Shipment Type
          as {shipment_type}
        </p>

        <div className="flex justify-end mt-4">
          <button
            className="bg-red-500 text-white px-4 py-1 rounded mr-2"
            onClick={handleDelete}
          >
            Delete
          </button>
          <button
            className="bg-gray-500 text-white px-4 py-1 rounded"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
        </div>
      </Modal>

      <UpdateAllTrackingModal
        isOpen={isUpdateAllTrackingModal}
        onClose={handleCloseUpdateAllTrackingModal}
        data={data}
      />
      <TanTable
        columnData={columnData}
        data={batches}
        loadingState={isLoading}
      />
    </div>
  );
};

export default CreateBatchesTable;
